import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCheckbox,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonModal,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
  useIonActionSheet,
} from "@ionic/react";
import { useEffect, useRef, useState } from "react";
import { chevronBack } from "ionicons/icons";
import { close } from "ionicons/icons";
import { useHistory, useParams } from "react-router";
import { ProductGridItem } from "../shopping-components/product-grid-list/ProductGridItem";
import { Footer, NoData } from "../../../components/index";
import { getShoppingList } from "../../../utils/getApiData";
import styles from "./ShoppingList.module.scss";
import { useUser } from "../../../Contexts/UserContext";
import { swapVerticalOutline } from "ionicons/icons";
import { filterOutline } from "ionicons/icons";
import data from '../../../partner.json'
import ReactGA from 'react-ga4'

export const ShoppingList = () => {
  const [categoryTitle, setCategoryTitle] = useState<any>("");
  const [productData, setProductData] = useState<any>([]);
  const [displayCategory, setDisplayCategory] = useState<any>("");
  const [filters, setFilters] = useState<any>([]);
  const [subCategory, setSubCategory] = useState<any>([]);
  const [appliedFilter, setAppliedFilter] = useState<any>({});
  const [payloadPage, setPayloadPage] = useState<number>(1);
  const [showLoading, setShowLoading] = useState(true);
  const [disableInfiniteScroll, setDisableInfiniteScroll] = useState(false);
  const [noData, setNoData] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState<any>();
  const [selectedSort, setSelectedSort] = useState<any>();
  const { category }: { category: string } = useParams();
  const { subcategory }: { subcategory: string } = useParams();
  const user = useUser();
  const modal = useRef<HTMLIonModalElement>(null);
  const menuslug =
    category && subcategory ? `/${category}/${subcategory}` : `/${category}`;
  const history = useHistory();

  const RequestParams = {
    menu_slug: decodeURIComponent(menuslug),
    page: payloadPage,
    response_type: "grid",
    pid: localStorage.getItem("pid")
      ? localStorage.getItem("pid")
      : Object.keys(data)[0],
    ...(user?.profileid && { profileid: user?.profileid }),
  };

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: history.location.pathname + history.location.search });
  }, [history]);

  useEffect(() => {
    const unlisten = history.listen(() => {
      closeModal();
      closeActionSheet();
    });
    return () => {
      unlisten();
    };
  }, [history])

  useEffect(() => {
    if (selectedFilter || selectedSort) {
      if (payloadPage === 1) {
        setProductData([]);
      }
      (async () => {
        if (payloadPage === 1) {
          setShowLoading(true);
        }
        try {
          const { data } = await getShoppingList({
            ...RequestParams,
            ...(selectedFilter && { filters: selectedFilter }),
            ...(selectedSort && { sortby: selectedSort }),
          });
          setCategoryTitle(data.categoryTitle);
          if (data.prods && data.prods.products.length > 0) {
            data?.filters &&
              setDisplayCategory(data.filters.specFilters[0].displayName);
            data?.filters &&
              (data.filters.specFilters[0]?.values
                ? setSubCategory(data.filters.specFilters[0].values)
                : setSubCategory(data.filters.specFilters[0].range));
            data?.filters && setFilters(data.filters.specFilters);
            setProductData((prevData: []) => [
              ...prevData,
              ...data.prods.products,
            ]);
            setDisableInfiniteScroll(data.prods.products.length < 10);
          } else {
            if (productData.length <= 0) {
              setNoData(true);
            } else {
              setDisableInfiniteScroll(true);
            }
          }
        } catch {
          setNoData(true);
        }
        setShowLoading(false);
      })();
    } else {
      if (payloadPage === 1) {
        setShowLoading(true);
      }
      (async () => {
        try {
          const { data } = await getShoppingList(RequestParams);
          setCategoryTitle(data.categoryTitle);
          if (data.prods && data.prods.products.length > 0) {
            data?.filters &&
              setDisplayCategory(data.filters.specFilters[0].displayName);
            data?.filters &&
              (data.filters.specFilters[0]?.values
                ? setSubCategory(data.filters.specFilters[0].values)
                : setSubCategory(data.filters.specFilters[0].range));
            data?.filters && setFilters(data.filters.specFilters);
            setProductData((prevData: []) => [
              ...prevData,
              ...data.prods.products,
            ]);
            setDisableInfiniteScroll(data.prods.products.length < 10);
          } else {
            if (productData.length <= 0) {
              setNoData(true);
            } else {
              setDisableInfiniteScroll(true);
            }
          }
        } catch {
          setNoData(true);
        }
        setShowLoading(false);
      })();
    }
  }, [payloadPage, selectedFilter, selectedSort]);

  const loadData = (ev: any) => {
    setPayloadPage((prevPage) => prevPage + 1);
    setTimeout(() => {
      ev.target.complete();
    }, 1000);
  };

  const getCategory = (category: any) => {
    setDisplayCategory(category.displayName);
    if (!category.values) {
      setSubCategory(category.range);
    } else {
      setSubCategory(category.values);
    }
  };
  const getCheckedItem = (event: any, item: any) => {
    if (Object.keys(appliedFilter).length === 0) {
      setAppliedFilter({ [displayCategory]: [item] });
    } else {
      if (appliedFilter[displayCategory]) {
        if (event.target.checked) {
          setAppliedFilter({
            ...appliedFilter,
            [displayCategory]: [...appliedFilter[displayCategory], item],
          });
        } else {
          setAppliedFilter({
            ...appliedFilter,
            [displayCategory]: appliedFilter[displayCategory].filter(
              (filterItem: any) => filterItem !== item
            ),
          });
        }
      } else {
        setAppliedFilter({ ...appliedFilter, [displayCategory]: [item] });
      }
    }
  };

  const isChecked = (item: any) => {
    if (Object.keys(appliedFilter).length !== 0) {
      if (appliedFilter[displayCategory]) {
        return appliedFilter[displayCategory].includes(item);
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const applyFilter = () => {
    const removeEmptyArray = Object.entries(appliedFilter).filter(
      (item: any) => item[1].length !== 0
    );
    if (removeEmptyArray.length !== 0) {
      setProductData([]);
      const selectedFilterObject = removeEmptyArray.reduce(
        (prev: any, item: any) => ({
          ...prev,
          [item[0]]: item[1].map((mapItem: any) => mapItem).join(","),
        }),
        {}
      );
      setSelectedFilter(selectedFilterObject);

      setAppliedFilter(
        removeEmptyArray.reduce(
          (prev: any, item: any) => ({
            ...prev,
            [item[0]]: item[1].map((mapItem: any) => mapItem),
          }),
          {}
        )
      );
      setNoData(false)
      setPayloadPage(1);
    } else if (
      !selectedFilter &&
      Object.entries(appliedFilter).keys.length === 0 &&
      removeEmptyArray.length === 0
    ) {
    } else {
      setProductData([]);
      setAppliedFilter({});
      setSelectedFilter(undefined);
      setPayloadPage(1);
    }
    closeModal();
  };


  const resetFilter = () => {
    if (!selectedFilter && Object.entries(appliedFilter).keys.length === 0) {
    } else {
      setNoData(false);
      setSelectedFilter(undefined);
      setAppliedFilter({});
      setProductData([]);
      setPayloadPage(1);
    }
    closeModal();
  };
  const applySort = (item: any) => {
    if (item !== selectedSort) {
      setPayloadPage(1);
      setSelectedSort(item);
      setProductData([]);
    }
  };
  const resetSort = (item: any) => {
    setPayloadPage(1);
    setSelectedSort(item);
    if (selectedSort) {
      setProductData([]);
    }
  };

  function closeModal() {
    modal.current?.dismiss();
  }

  function closeActionSheet() {
    dismiss();
  }
  const [present, dismiss] = useIonActionSheet();

  return (
    <IonPage className={styles.shoppingList}>
      <IonHeader className="ion-no-padding ion-no-border">
        <IonToolbar className="header-primary-bg-color header-text-color">
          <IonGrid fixed>
            <IonRow>
              <IonButtons>
                <IonBackButton
                  icon={chevronBack}
                  defaultHref="/shopping"
                ></IonBackButton>
              </IonButtons>
              <IonTitle className={styles.productListTitle}>
                {categoryTitle || ""}
              </IonTitle>
            </IonRow>
          </IonGrid>
        </IonToolbar>
      </IonHeader>
      <IonContent className={styles.content}>
        <div className="scrollableDesign">
          <div className="scrollable"></div>
        </div>
        <IonGrid fixed>
          {/* SORT BY */}
          <IonRow>
            <IonCol size="6">
              <IonButton
                expand="block"
                className={`ion-no-margin ${selectedSort ? 'primary-btn' : 'white-btn'
                  }`}
                onClick={() =>
                  present({
                    header: "Sort By",
                    cssClass: ["custom-sheet"],
                    buttons: [
                      {
                        text: "Relevance",
                        role: "destructive",
                        handler() {
                          resetSort(undefined);
                        },
                      },
                      {
                        text: "Popular",
                        role: `${selectedSort === "popular" && "selected"}`,
                        handler: () => {
                          applySort("popular");
                        },
                      },
                      {
                        text: "Price Low",
                        role: `${selectedSort === "pricelow" && "selected"}`,
                        handler: () => {
                          applySort("pricelow");
                        },
                      },
                      {
                        text: "Price High",
                        role: `${selectedSort === "pricehigh" && "selected"}`,
                        handler: () => {
                          applySort("pricehigh");
                        },
                      },
                      {
                        text: "Discount Low",
                        role: `${selectedSort === "discountlow" && "selected"}`,
                        handler: () => {
                          applySort("discountlow");
                        },
                      },
                      {
                        text: "Discount High",
                        role: `${selectedSort === "discounthigh" && "selected"
                          }`,
                        handler: () => {
                          applySort("discounthigh");
                        },
                      },
                    ],
                  })
                }
              >
                <IonIcon slot="start" icon={swapVerticalOutline} />
                Sort
              </IonButton>
            </IonCol>

            {/* FILTER */}
            <IonCol size="6">
              <IonButton
                id="open-modal"
                expand="block"
                className={`ion-no-margin ${selectedFilter ? 'primary-btn' : 'white-btn'
                  }`}
                disabled={filters.length === 0}
              >
                <IonIcon slot="start" icon={filterOutline} />
                Filter
              </IonButton>

              <IonModal
                trigger="open-modal"
                initialBreakpoint={1}
                breakpoints={[1]}
                ref={modal}
                handleBehavior="cycle"
              >
                <IonContent
                  className={`ion-bg-white ${styles.filterModal}`}
                  scrollY={true}
                >
                  <IonItem class={styles.filterHeader}>
                    <p className="ion-padding-start font14 font500 primary-title-color">
                      All Filters
                    </p>
                    <IonIcon
                      slot="end"
                      icon={close}
                      className="dark"
                      onClick={() => closeModal()}
                    />
                  </IonItem>
                  <IonGrid className="ion-no-padding">
                    <IonRow className="ion-no-padding">
                      <IonCol
                        className={`ion-no-padding ${styles.subCategory}`}
                        size="5"
                      >
                        {filters.map((item: any) => (
                          <IonList
                            key={item.displayName}
                            className="ion-no-padding ion-no-border"
                          >
                            <IonItem
                              className={`ion-no-padding ${styles.filterCategoryItem}`}
                              onClick={() => getCategory(item)}
                            >
                              <IonButton
                                className={`ion-no-margin ${item.displayName === displayCategory ? 'white-btn' : 'tertiary-btn'}`}
                              >
                                <IonLabel className="font12 font500 padl10">
                                  {item.displayName}
                                </IonLabel>
                              </IonButton>
                            </IonItem>
                          </IonList>
                        ))}
                      </IonCol>
                      <IonCol
                        className={`ion-no-padding ${styles.filterSubcategory}`}
                        size="7"
                      >
                        {subCategory.map((items: any) => (
                          <IonList key={items} className="ion-no-padding">
                            <IonItem className="ion-bg-white ion-no-padding">
                              <IonCheckbox
                                mode="ios"
                                checked={isChecked(items)}
                                className="ion-margin-start checkbox"
                                onClick={(event) =>
                                  getCheckedItem(event, items)
                                }
                              />
                              <IonLabel
                                className={`marl4 font12 ion-text-capitalize ${isChecked(items) ? "font500" : "font400"
                                  } ${styles.subCategoryLabel}`}
                              >
                                {items}
                              </IonLabel>
                            </IonItem>
                          </IonList>
                        ))}
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonContent>
                <IonFooter>
                  <IonButton
                    className={`ion-no-margin white-btn font14 font500`}
                    expand="block"
                    onClick={() => resetFilter()}
                  >
                    Reset
                  </IonButton>
                  <IonButton
                    className={`ion-no-margin font14 font500 priimary-btn`}
                    expand="block"
                    onClick={() => applyFilter()}
                  >
                    Apply
                  </IonButton>
                </IonFooter>
              </IonModal>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="12">
              <IonItem lines="none" className={`${styles.title}`}>
                <IonLabel>Products</IonLabel>
              </IonItem>
            </IonCol>
          </IonRow>
        </IonGrid>
        {noData ? (
          <NoData props={"No Data Found !"} />
        ) : (
          <>
            <IonGrid fixed className={styles.contentGrid}>
              <IonRow>
                {productData &&
                  productData.map((productDetail: {}, index: number) => (
                    <IonCol className="ion-no-padding" size="6" key={index}>
                      <ProductGridItem item={productDetail} />
                    </IonCol>
                  ))}
              </IonRow>
            </IonGrid>
            <IonInfiniteScroll
              onIonInfinite={loadData}
              disabled={disableInfiniteScroll}
            >
              <IonInfiniteScrollContent
                color="primary"
                loadingSpinner="crescent"
              ></IonInfiniteScrollContent>
            </IonInfiniteScroll>
            {(productData.length > 0 && disableInfiniteScroll) && <Footer />}
          </>
        )}
      </IonContent>
      <IonLoading
        cssClass="my-custom-class"
        isOpen={showLoading}
        message={"Please wait..."}
      />
    </IonPage>
  );
};


