import axios from "axios";
import {
  ESD_API_DOMAIN,
  GET_SHORT_URL,
  GET_APP_PAGE,
  GET_DEAL,
  GET_SHOPPING_LIST,
  GET_SHOPPING_Detail,
  GET_SHOPPING_APPLIANCES_Detail_,
  GET_STORE_AFFILIATE,
} from "../apiConfig";
import * as StringHelpers from "./helperService";
import data from "../partner.json";

const getDefaultParams = () => {
  return {
    key: localStorage.getItem("key")
      ? localStorage.getItem("key")
      : Object.values(data.partners)[0]?.key,
    pid: localStorage.getItem("pid")
      ? localStorage.getItem("pid")
      : Object.values(data.partners)[0]?.pid,
    num: 30,
  };
};

export function getAllDeals(customParams: Object) {
  const defaultParams = getDefaultParams();
  const finalrequestParams = { ...defaultParams, ...customParams };
  const ApiParameter = {
    url: StringHelpers.getAPI(ESD_API_DOMAIN, GET_APP_PAGE, finalrequestParams),
    method: "get",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    },
  };
  return axios(ApiParameter).then((response) => {
    return response;
  });
}

export function getDealsList(customParams: Object, body: Object) {
  const defaultParams = getDefaultParams();
  const finalrequestParams = { ...defaultParams, ...customParams };
  const ApiParameter = {
    url: StringHelpers.getAPI(ESD_API_DOMAIN, GET_APP_PAGE, finalrequestParams),
    method: "post",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    },
    ...(Object.entries(body).length !== 0 && { data: JSON.stringify(body) }),
  };

  return axios(ApiParameter).then((response) => {
    return response;
  });
}

export function getDealDetail(customParams: Object) {
  const defaultParams = getDefaultParams();
  const finalrequestParams = { ...defaultParams, ...customParams };
  const ApiParameter = {
    url: StringHelpers.getAPI(ESD_API_DOMAIN, GET_DEAL, finalrequestParams),
    method: "get",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    },
  };

  return axios(ApiParameter).then((response) => {
    return response;
  });
}

export function getSearchedData(customParams: Object, body: Object) {
  const defaultParams = getDefaultParams();
  const finalrequestParams = { ...defaultParams, ...customParams };
  const ApiParameter = {
    url: StringHelpers.getAPI(ESD_API_DOMAIN, GET_APP_PAGE, finalrequestParams),
    method: "post",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    },
    ...(Object.entries(body).length !== 0 && { data: JSON.stringify(body) }),
  };

  return axios(ApiParameter).then((response) => {
    return response;
  });
}

export function getAllCoupons(customParams: Object) {
  const defaultParams = getDefaultParams();
  const finalrequestParams = { ...defaultParams, ...customParams };
  const ApiParameter = {
    url: StringHelpers.getAPI(ESD_API_DOMAIN, GET_APP_PAGE, finalrequestParams),
    method: "get",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    },
  };

  return axios(ApiParameter).then((response) => {
    return response;
  });
}
export function getCouponDetail(customParams: Object) {
  const defaultParams = getDefaultParams();
  const finalrequestParams = { ...defaultParams, ...customParams };
  const ApiParameter = {
    url: StringHelpers.getAPI(ESD_API_DOMAIN, GET_APP_PAGE, finalrequestParams),
    method: "get",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    },
  };

  return axios(ApiParameter).then((response) => {
    return response;
  });
}

export function getShoppingData(customParams: Object) {
  const defaultParams = getDefaultParams();
  const finalrequestParams = { ...defaultParams, ...customParams };
  const ApiParameter = {
    url: StringHelpers.getAPI(ESD_API_DOMAIN, GET_APP_PAGE, finalrequestParams),
    method: "get",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    },
  };

  return axios(ApiParameter).then((response) => {
    return response;
  });
}

export function getShoppingList(body: Object) {
  const defaultParams = getDefaultParams();
  const finalrequestParams = { ...defaultParams };
  const ApiParameter = {
    url: StringHelpers.getAPI(
      ESD_API_DOMAIN,
      GET_SHOPPING_LIST,
      finalrequestParams
    ),
    method: "post",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    },
    data: JSON.stringify(body),
  };

  return axios(ApiParameter).then((response) => {
    return response;
  });
}
export function getShoppingDetail(customParams: Object) {
  const defaultParams = getDefaultParams();
  const finalrequestParams = { ...defaultParams, ...customParams };
  const ApiParameter = {
    url: StringHelpers.getAPI(
      ESD_API_DOMAIN,
      GET_SHOPPING_Detail,
      finalrequestParams
    ),
    method: "get",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    },
  };

  return axios(ApiParameter).then((response) => {
    return response;
  });
}
export function getShoppingAppliancesDetail(customParams: Object) {
  const defaultParams = getDefaultParams();
  const finalrequestParams = { ...defaultParams, ...customParams };
  const ApiParameter = {
    url: StringHelpers.getAPI(
      ESD_API_DOMAIN,
      GET_SHOPPING_APPLIANCES_Detail_,
      finalrequestParams
    ),
    method: "get",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    },
  };

  return axios(ApiParameter).then((response) => {
    return response;
  });
}
export function getStoreAffiliateInfo(customParams: Object) {
  const defaultParams = getDefaultParams();
  const finalrequestParams = { ...defaultParams, ...customParams };
  const ApiParameter = {
    url: StringHelpers.getAPI(
      ESD_API_DOMAIN,
      GET_STORE_AFFILIATE,
      finalrequestParams
    ),
    method: "get",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    },
  };

  return axios(ApiParameter).then((response) => {
    return response;
  });
}

export function getShareUrl(body: any) {
  const finalrequestParams = {};
  const ApiParameter = {
    url: StringHelpers.getAPI(
      ESD_API_DOMAIN,
      GET_SHORT_URL,
      finalrequestParams
    ),
    method: "post",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    },
    data: JSON.stringify(body),
  };

  return axios(ApiParameter).then((response) => {
    return response;
  });
}

export function getStoreList(body: Object,data: object) {
  const defaultParams = getDefaultParams();
  const finalrequestParams = { ...defaultParams,...body };
  const ApiParameter = {
    url: StringHelpers.getAPI(
      ESD_API_DOMAIN,
      GET_APP_PAGE,
      finalrequestParams
    ),
    method: "post",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    },
    data: JSON.stringify(data),
  };

  return axios(ApiParameter).then((response) => {
    return response;
  });
}