import {
  IonCard,
  IonButton,
  IonLabel,
  IonItem,
  IonTitle,
} from '@ionic/react';
import React from 'react';
import { esdDealCardThumbnail, categoryThumbnail } from '../../../../thumbnailConfig';
import styles from './StoreCarousel.module.scss';
import { useHistory } from 'react-router';
import { googleAnalyticsCampaigns } from '../../../../utils/reactGa';
import { usePartner } from '../../../../Contexts/PartnerContext';

type Props = {
  props: any;
};

const handleButtonText = (item: any) => {
  if (item) {
    const numberRegex = item.match(/(\d+(\.\d+)?)/);
    const percentageRegex = item.match(/(\d+(\.\d+)?)%/);
    if (percentageRegex) {
      return item.replace(percentageRegex[0], `<span>${percentageRegex[0]}</span>`)
    } else if (numberRegex) {
      return item.replace(numberRegex[0], `<span>${numberRegex[0]}</span>`)
    } else {
      return item;
    }
  }
  else {
    return 'go to store'
  }
}

export const StoreCarousel = ({ props }: Props) => {
  const { partnerPid } = usePartner();
  const history = useHistory();
  const pagePath = history.location.pathname.substring(1)
  const gaHandler = (item: any, index: number) => {
    googleAnalyticsCampaigns(pagePath, item, index);
  }

  const handleImageFailure = (e: any) => {
    e.target.src = partnerPid === 'esd' ? esdDealCardThumbnail : categoryThumbnail;
  }

  const pageRedirectHandler = (item: any, index: any) => {
    gaHandler(item, index)
    if (item?.affInfoRedirect === 1) {
      history.push({ pathname: `/commission/${item.name}`, search: `url=${encodeURIComponent(item.url)}` })
    } else {
      window.open(item.url, '_blank')
    }
  }
  return (
    <div className={styles.storesCarousel}>
      <IonItem lines="none" class='ion-no-padding header'> 
        <IonTitle class='ion-no-padding'>
          <h1 className='ion-text-capitalize ion-margin primary-title-color'>{props?.display_name}</h1>
        </IonTitle>   
        <IonButton
          onClick={() => {  history.push("/store/list")}}
          className="font12 ion-no-padding  clear-btn">
            View More
        </IonButton>
      </IonItem>
      <div className='scrollx'>
        {props?.stores.map((item: any, index: number) => {
          return (
            <div className={`scroll-item`} key={index}>
              <IonCard onClick={() => pageRedirectHandler(item, index)}>
                <div className={`${styles.imgContainer}`}>
                  <img src={item?.image} alt="deal" onError={(e) => handleImageFailure(e)} />
                </div>
                <div className={`${styles.storeName} ion-text-uppercase`}>
                  <IonLabel>{item.name}</IonLabel>
                </div>
                <IonButton className='ion-text-uppercase marl10 marr10 mart10 marb10 secondary-btn' expand='block'><div className={`${styles.commission} text-len`} dangerouslySetInnerHTML={{ __html: handleButtonText(item.title) }}></div></IonButton>
              </IonCard>
            </div>
          );
        })}
      </div>
    </div >
  );
};
