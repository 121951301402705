import { IonAccordion, IonAccordionGroup, IonButton, IonCard, IonCol, IonGrid, IonImg, IonItem, IonRow, IonText } from "@ionic/react";
import styles from './PriceCompare.module.scss'
import { googleAnalyticsViewStore } from "../../../../utils/reactGa";
import { esdCategoryThumbnail, categoryThumbnail } from "../../../../thumbnailConfig";
import { usePartner } from "../../../../Contexts/PartnerContext";
import { useHistory } from "react-router";

type Props = {
  comparison: any;
};
export const PriceCompare = ({ comparison }: Props) => {
  const { partnerPid } = usePartner();
  const history = useHistory();
  const groupedData = comparison?.results?.reduce((acc: any, item: any) => {
    const store = item?.store;
    if (!acc.hasOwnProperty(store)) {
      acc[store] = { store, items: [] };
    }
    acc[store].items.push(item);
    return acc;
  }, {});

  const sortedGroups = groupedData && Object.values(groupedData)

  const gaHandler = (url: string) => {
    googleAnalyticsViewStore(comparison?.logParams, url);
  }

  const redirectHandler = (store: any, url: any, earnUpto: any, title: string) => {
    if (store === 'amazon' || store === 'flipkart') {
      history.push({ pathname: `/commission/${store}`, search: `url=${encodeURIComponent(url)}&noCashback=${earnUpto.noCashback}&displayText=${encodeURIComponent(earnUpto.displayText)}&title=${encodeURIComponent(title)}` })
    } else {
      window.open(url, '_blank')
    }
  }

  const handleImageFailure = (e: any) => {
    e.target.src = partnerPid === 'esd' ? esdCategoryThumbnail : categoryThumbnail;
  }

  return (
    <div className={`${styles.PriceCompare}`}>
      <IonGrid className="ion-no-padding ion-margin-top">
        <IonRow>
          <IonCol className="ion-no-padding">
            <IonAccordionGroup>
              {sortedGroups && sortedGroups?.map((group: any, index: number) => (
                <IonAccordion key={index}>
                  <IonItem
                    slot="header"
                    className="ion-no-margin ion-no-padding"
                    lines="none"
                  >
                    <IonCard className="ion-justify-content-between ion-align-items-center ion-no-margin ion-no-padding">
                      <img src={group.items[0].store_logo} alt="deal" onError={(e) => handleImageFailure(e)} />
                      <IonText className="ion-no-margin">
                        {group.items[0].price === 0 ? 'Check Price' : (
                          <strong className={group.items[0]?.rewards_campaign?.displayText ? `font900 font16 accordion-title-color` : `${styles.price} font900 font16 accordion-title-color`}>&#8377;{group.items[0].price}</strong>
                        )}
                        <span className={group.items[0]?.rewards_campaign?.displayText ? `font400 ${styles.cashbackText}` : `font400 ${styles.removeCashback}`}>
                          <span className={group.items[0]?.rewards_campaign?.noCashback === 1 ? 'accordion-danger-text-color' : 'accordion-special-text-color'}>{group.items[0]?.rewards_campaign?.displayText}</span>
                        </span>
                        <span className={`${styles.variantsText} accordion-grey-text-color`}>Variants</span>
                      </IonText>
                      {group.items[0].instock === 1 ? (
                        <IonButton
                          className="ion-no-margin ion-no-padding ion-text-capitalize secondary-btn"
                          onClick={() => { gaHandler(group.items[0].url); redirectHandler(group.items[0].store, group.items[0].url, group.items[0].rewards_campaign, group.items[0].title) }}
                        >
                          Visit Store
                        </IonButton>
                      ) : (
                        <IonButton
                          className="ion-no-margin ion-no-padding disable-btn"
                          href={group.items[0].url}
                          target="_blank"
                        >
                          Out of Stock
                        </IonButton>
                      )}
                    </IonCard>
                  </IonItem>
                  <div slot="content">
                    {group.items.map((item: any, index: any) => (
                      <IonCard className="ion-justify-content-between ion-align-items-center ion-no-margin ion-no-padding" key={index}>
                        <span className={`${styles.itemTitleContainer}`}>
                          <IonText className={`${styles.itemTitle} accordion-title-color`}>{item.title}</IonText>
                        </span>
                        <IonText>
                          {item.price === 0 ? 'Check Price' : (
                            <strong className="font900 font16 accordion-title-color">&#8377;{item.price}</strong>
                          )}
                          <span className={`${styles.cashbackText} ${item.rewards_campaign?.noCashback === 1 ? 'accordion-danger-text-color' : 'accordion-special-text-color'} font400 accordion-title-color`}>
                            {item?.rewards_campaign?.displayText}
                          </span>
                        </IonText>
                        {item.instock === 1 ? (
                          <IonButton
                            shape="round"
                            className="ion-no-margin ion-no-padding ion-text-capitalize secondary-btn"
                            onClick={() => { gaHandler(item.url); redirectHandler(item.store, item.url, item.rewards_campaign, item.title) }}
                          >
                            Visit Store
                          </IonButton>
                        ) : (
                          <IonButton
                            shape="round"
                            className="ion-no-margin ion-no-padding disable-btn"
                            href={item.url}
                            target="_blank"
                          >
                            Out of Stock
                          </IonButton>
                        )}
                      </IonCard>
                    ))}
                  </div>
                </IonAccordion>
              ))}
            </IonAccordionGroup>

          </IonCol>
        </IonRow>
      </IonGrid>
    </div >
  );
};

function UseParter(): { partnerPid: any; } {
  throw new Error("Function not implemented.");
}
