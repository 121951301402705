import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { DealHomePage, DealDetailPage, DealsListPage, CouponsHome, CouponDetail, ShoppingHome, ShoppingList, ShoppingDetail, CommissionDetail } from './pages/index'
import { NoData, Search } from './components/index';
import { animationBuilder } from './PageAnimation'
import ReactGA from 'react-ga4'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/default.css';
import './theme/paynearby.css';
import './theme/mos.css';
import './theme/easidesi.css';
import './theme/ast.css';
import './global.scss';
import './theme/spta.css';
import './theme/pwa.css'
import './theme/itt.css';
import './theme/plx.css';

import { ProfileIdProvider } from './Contexts/UserContext';
import { usePartner } from './Contexts/PartnerContext';
import { useEffect } from 'react';
import { StoreList } from './pages/store/store-list/StoreList';


setupIonicReact();

const App: React.FC = () => {
  ReactGA.initialize('G-13ENBQSHZZ');
  const { theme, partnerPid } = usePartner();
  useEffect(() => {
    if (partnerPid) {
      const faviconLink = document.createElement('link');
      faviconLink.rel = 'shortcut icon';
      faviconLink.href = `/assets/icon/${theme}.png`;
      faviconLink.type = "image/png"
      faviconLink.id = "favicon"
      const existingFavicon = document.getElementById('favicon');
      if (existingFavicon) {
        document.head.removeChild(existingFavicon);
      }
      document.head.appendChild(faviconLink);
    }
  }, [partnerPid]);

  return (
    <IonReactRouter>
      <ProfileIdProvider>
        <IonApp className={`${theme}`}>
          <IonRouterOutlet animation={animationBuilder}>
            <Redirect from="/" to="/shopping" exact={true} />
            <Route path="/deals" component={DealHomePage} exact={true} />
            <Route path="/deal/:id" component={DealDetailPage} exact={true} />
            <Route path="/deals/:category/:subcategory" component={DealsListPage} exact={true} />
            <Route path="/coupons" component={CouponsHome} exact={true} />
            <Route path="/coupons/:id" component={CouponDetail} exact={true} />
            <Route path="/shopping" exact component={ShoppingHome} />
            <Route path="/shopping/pricelist/:category" exact component={ShoppingList} />
            <Route path="/shopping/pricelist/:category/:subcategory" exact component={ShoppingList} />
            <Route path="/shopping/product/:productid" exact component={ShoppingDetail} />
            <Route path="/:searchPage/search" component={Search} exact={true} />
            <Route path="/commission/:store" component={CommissionDetail} exact={true} />
            <Route path="/store/list" component={StoreList} exact={true} />
            <Route render={() => <NoData props="404" />} />
          </IonRouterOutlet >
        </IonApp >
      </ProfileIdProvider>
    </IonReactRouter >
  )
}

export default App;
