import {
  IonGrid,
  IonRow,
  IonCol,
  IonTitle,
  IonContent,
  IonPage,
  IonCard,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCardContent,
  IonLabel,
  IonButton,
  IonBackButton,
  IonSegment,
  IonSegmentButton,
  IonLoading,
  IonIcon,
} from '@ionic/react';
import { chevronBack, shareSocialOutline } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { getShoppingDetail, getShoppingAppliancesDetail } from '../../../utils/getApiData';
import { Cashback, Footer, NoData } from '../../../components/index';
import { PriceCompare } from '../shopping-components/price-compare/PriceCompare';
import { ProductSpecs } from '../shopping-components/product-specs/ProductSpecs';
import { useUser } from '../../../Contexts/UserContext';
import { esdShoppingDetailThumbnail, bannerThumbnail } from '../../../thumbnailConfig';
import styles from './ShoppingDetail.module.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Keyboard, Scrollbar, Zoom } from 'swiper';
import ReactGA from 'react-ga4'
import { ShareProduct } from "../../../components/index";

import 'swiper/swiper.min.css';
import '@ionic/react/css/ionic-swiper.css';
import 'swiper/css/pagination';
import { googleAnalyticsViewStore } from '../../../utils/reactGa';
import { usePartner } from '../../../Contexts/PartnerContext';

export const ShoppingDetail = () => {
  const [content, setContent] = useState('compare');
  const [data, setData] = useState<any>();
  const [showLoading, setShowLoading] = useState(true);
  const [noData, setNoData] = useState(false);
  const [shareModal, setShareModal] = useState(false)
  const { partnerPid } = usePartner();
  let paramId: { productid: string } = useParams();
  let productId = paramId.productid;
  const user = useUser();
  const history = useHistory();

  const ShoppingAppliancesRequestParams = {
    type: 'json',
    category: 'product_id',
    product_id: productId,
    ...(user?.profileid && { profileid: user?.profileid }),
  };

  const ShoppingRequestParams = {
    pageId: 21,
    sendSelectedTab: 1,
    page: 1,
    productId: productId,
    category: 'product_id',
    type: 'json',
    ...(user?.profileid && { profileid: user?.profileid }),
  };

  const gaHandler = (item: any, url: any) => {
    googleAnalyticsViewStore(item, url);
  }

  const redirectHandler = (store: any, url: any, earnUpto: any, title: string) => {
    if (store === 'amazon' || store === 'flipkart') {
      history.push({ pathname: `/commission/${store}`, search: `url=${encodeURIComponent(url)}&noCashback=${earnUpto.noCashback}&displayText=${encodeURIComponent(earnUpto.displayText)}&title=${encodeURIComponent(title)}` })
    }
    else {
      window.open(url, '_blank');
    }
  }

  const handleImageFailure = (e: any) => {
    e.target.src = partnerPid === 'esd' ? esdShoppingDetailThumbnail : bannerThumbnail;
  }

  const shareButtonHandler = () => {
    setShareModal(true)
  }

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: history.location.pathname + history.location.search });
  }, [history]);

  useEffect(() => {
    (async () => {
      try {
        const { data } = productId.includes('_')
          ? await getShoppingDetail(ShoppingRequestParams)
          : await getShoppingAppliancesDetail(ShoppingAppliancesRequestParams);
        data['metaInfo'] ? setData(data) : setNoData(true);
      } catch {
        setNoData(true);
      }
      setShowLoading(false);
    })();
  }, [productId]);

  return (
    <IonPage className={styles.shoppingDetail}>
      <IonHeader className='ion-no-padding ion-no-border'>
        <IonToolbar className='header-primary-bg-color header-text-color'>
          <IonGrid fixed>
            <IonRow>
              <IonButtons>
                <IonBackButton icon={chevronBack} defaultHref='/shopping'></IonBackButton>
              </IonButtons>
              {data?.metaInfo?.listing_id ?
                (data?.metaInfo?.listing_id !== null || data?.metaInfo?.listing_id !== '') && (
                  <IonTitle className={`${styles.productDetailTitle} ion-text-uppercase`}>
                    {data?.metaInfo?.store}
                  </IonTitle>
                ) : <IonTitle className={`${styles.productDetailTitle} ion-text-uppercase`}>
                  {data && (content === "compare" ? 'price compare' : 'specs' || "ProductDetail")}
                </IonTitle>}
            </IonRow>
          </IonGrid>
        </IonToolbar>
      </IonHeader>
      {noData ? (
        <>
          <div className='scrollableDesign'>
            <div className='scrollable'></div>
          </div>
          <NoData props={'No Data Found !'} />
        </>
      ) : (
        <IonContent className={styles.content}>
          <div className='scrollableDesign'>
            <div className='scrollable'></div>
          </div>
          <IonGrid fixed className={styles.contentGrid}>
            <IonRow>
              {data && (
                <IonCol>
                  <Swiper
                    className={`${styles.swiper}`}
                    modules={[Autoplay, Keyboard, Pagination, Scrollbar, Zoom]}
                    autoplay={{ delay: 2500 }}
                    pagination={{ clickable: true }}>
                    {data && data?.metaInfo?.imageVariants ? (
                      data.metaInfo.imageVariants.map((image: string, index: number) => (
                        <SwiperSlide key={index}>
                          <IonCard>
                            <img src={image} alt='img' className='ion-padding' onError={(e) => handleImageFailure(e)} />
                            <IonIcon icon={shareSocialOutline} className='shareButton icon-dark-color' onClick={() => shareButtonHandler()}></IonIcon>
                          </IonCard>
                        </SwiperSlide>
                      ))
                    ) : (
                      <SwiperSlide>
                        <IonCard>
                          <img
                            src={data?.metaInfo?.img}
                            alt={data?.metaInfo?.title}
                            className='ion-padding'
                            onError={(e) => handleImageFailure(e)}
                          />
                        </IonCard>
                        <IonIcon icon={shareSocialOutline} className='shareButton icon-dark-color' onClick={() => shareButtonHandler()}></IonIcon>
                      </SwiperSlide>
                    )}
                  </Swiper>
                  {<ShareProduct state={{ shareModal, setShareModal }} data={data?.metaInfo} page={'shopping'} />}
                  <div className='ion-padding'>
                    <IonCardHeader className='ion-no-padding'>
                      <IonCardTitle className='ion-text-capitalize card-title-color'>
                        {data?.metaInfo?.brand}
                      </IonCardTitle>
                      <IonCardSubtitle className='card-subTitle-color'>{data?.metaInfo?.title}</IonCardSubtitle>
                      <span className='font12 ion-text-capitalize card-grey-text-color'>
                        By {data?.metaInfo?.store}
                      </span>
                    </IonCardHeader>
                    <IonCardContent className='ion-no-padding'>
                      <IonGrid className='ion-no-padding'>
                        <IonRow className='ion-align-items-center ion-padding-top'>
                          <span className={`${styles.offerPrice} ion-padding-end font900 card-title-color`}>
                            <IonLabel>&#8377;{data?.metaInfo?.finalPrice}</IonLabel>
                          </span>
                          {data?.metaInfo?.origPrice > data?.metaInfo?.finalPrice ? (
                            <>
                              <span>
                                <span className={`${styles.originalPrice} ion-padding-horizontal card-title-color`}>
                                  &#8377;{data?.metaInfo?.origPrice}
                                </span>
                                <IonLabel className='success'>
                                  (
                                  {Math.round(
                                    ((data?.metaInfo?.origPrice - data?.metaInfo?.finalPrice) /
                                      data?.metaInfo?.origPrice) *
                                    100
                                  )}
                                  % off)
                                </IonLabel>
                              </span>
                            </>
                          ) : (
                            ''
                          )}
                        </IonRow>
                        <IonRow>
                          <Cashback rewardText={data?.metaInfo?.rewards_campaign} styleName={'details'} />
                        </IonRow>
                      </IonGrid>
                    </IonCardContent>
                  </div>
                  {/* price comparison starts */}
                  <div>
                    <IonButton
                      shape='round'
                      expand='full'
                      className='ion-text-uppercase secondary-btn'
                      target='blank'
                      onClick={() => {
                        gaHandler(data?.logParams, productId.includes('_') ? data?.metaInfo?.url : data?.metaInfo?.storeLink);
                        redirectHandler(data?.metaInfo?.store, productId.includes('_') ? data?.metaInfo?.url : data?.metaInfo?.storeLink, data?.metaInfo?.rewards_campaign, data?.metaInfo?.title);
                      }}
                    >
                      BUY FROM {data?.metaInfo?.store}
                    </IonButton>
                  </div>
                  <div>
                    {data?.results || data?.featureAttribute ? (
                      <IonToolbar>
                        <IonSegment value={content} onIonChange={(e: any) => setContent(e.target.value)}>
                          <IonSegmentButton value='compare'>Compare</IonSegmentButton>
                          <IonSegmentButton value='specs'>Specs</IonSegmentButton>
                        </IonSegment>
                      </IonToolbar>
                    ) : (
                      ''
                    )}

                    {content === 'compare' ? (
                      <PriceCompare comparison={data} />
                    ) : (
                      <ProductSpecs specs={data?.featureAttribute} />
                    )}
                  </div>
                </IonCol>
              )}
            </IonRow>
          </IonGrid>
          {data && <Footer />}
        </IonContent>
      )}

      <IonLoading cssClass='my-custom-class' isOpen={showLoading} message={'Please wait...'} />
    </IonPage>
  );
};
