import { createContext, useContext } from "react";
import { useHistory } from "react-router";

type ProfileContextType = {
  profileid: string | null
}
const UserContext = createContext<ProfileContextType | null>(null);

const ProfileIdProvider = ({ children }: any) => {
  const history = useHistory();
  const userProfileId: any = new URLSearchParams(history.location.search).get("profile_id");

  if (!localStorage.getItem("userId")) {
    if (userProfileId) {
      localStorage.setItem("userId", userProfileId)
    }
  } else if (userProfileId !== null) {
    localStorage.setItem("userId", userProfileId)
  }

  const user_Id = localStorage.getItem("userId")

  return <UserContext.Provider value={{ profileid: user_Id }}>{children}</UserContext.Provider>;
};

const useUser = () => useContext(UserContext);
export { useUser, ProfileIdProvider };