import {
  IonCard,
  IonCardContent,
  IonCol,
  IonGrid,
  IonImg,
  IonRow,
  IonText,
} from "@ionic/react";
import { Cashback } from "../../../../components";
import { esdShoppingListCardThumbnail, productThumbnail } from "../../../../thumbnailConfig";
import styles from "./ProductGridItem.module.scss";
import { googleAnalyticsViewItem } from "../../../../utils/reactGa";
import { usePartner } from "../../../../Contexts/PartnerContext";

type Props = {
  item: any;
};

export const ProductGridItem = ({ item }: Props) => {
  const { partnerPid } = usePartner();
  const gaHandler = () => {
    googleAnalyticsViewItem(item?.logParams);
  };
  const handleImageFailure = (e: any) => {
    e.target.src = partnerPid === 'esd' ? esdShoppingListCardThumbnail : productThumbnail;
  }
  return (
    <IonGrid className={`${styles.productGridItem} ion-no-padding`}>
      <IonRow>
        <IonCol>
          <IonCard
            className="ion-no-margin"
            onClick={() => gaHandler()}
            routerLink={`/shopping/product/${item.id || item.listing_id}`}
          >
            <div className={styles.productImg}>
              <img src={item?.img} alt="product" onError={(e) => handleImageFailure(e)} />
            </div>
            <div className={`${styles.itemDetail} pad10`}>
              <IonText
                className={`${styles.title} line-2-clip font14 ion-no-margin card-title-color`}
              >
                {item.title}
              </IonText>
              <IonCardContent className="ion-no-padding">
                <div className={`${styles.price}`}>
                  <IonText
                    className={`${styles.productListProductPrice} font12 card-title-color`}
                  >
                    {item.origPrice > item.price && (
                      <strong>&#8377;{item.origPrice.toFixed(0)}</strong>
                    )}
                  </IonText>
                  &nbsp;&nbsp;
                  <IonText className="font12 card-title-color">
                    <strong>&#8377;{item.price.toFixed(0)}</strong>
                  </IonText>
                  <IonText className="font12 card-title-color">
                    &nbsp;
                    {item.origPrice > item.price &&
                      `(${Math.round(
                        ((item.origPrice - item.price) / item.origPrice) * 100
                      )}% off)`}
                  </IonText>
                </div>
                <IonRow>
                  <Cashback
                    rewardText={item.rewards_campaign}
                    styleName={"cashback"}
                  />
                </IonRow>
              </IonCardContent>
            </div>
          </IonCard>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};
