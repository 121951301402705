import { createContext, useContext, useEffect, useState } from "react";
import data from "../partner.json";

const PartnerContext = createContext<any>(null);

const PartnerContextProvider = ({ children }: any) => {
  const { partners } = data;
  const [theme, setTheme] = useState<any>();
  const [partnerPid, setPartnerPid] = useState<any>();

  useEffect(() => {
    let params = getQueryParams();
    if (typeof params?.pid !== "undefined" && params?.pid !== "") {
      localStorage.setItem("pid", params?.pid);
      const partner = getPartnerById(params?.pid);
      localStorage.setItem("key", partner[0][1]?.key);
      setTheme(partner[0][1]?.theme);
      setPartnerPid(partner[0][1]?.pid)
    } else {
      const parterDomain = window.location.hostname;
      const partner = getPartnerByDomain(parterDomain);
      if (partner.length !== 0) {
        localStorage.setItem("pid", partner[0][1].pid);
        localStorage.setItem("key", partner[0][1].key);
        setTheme(partner[0][1]?.theme);
        setPartnerPid(partner[0][1]?.pid)
      } else {
        window.location.href = 'https://paynearby.scandid.in'
      }
    }
  }, []);

  const getQueryParams = () =>
    window.location.search
      .replace("?", "")
      .split("&")
      .reduce(
        (r: any, e: any) => (
          (r[e.split("=")[0]] = decodeURIComponent(e.split("=")[1])), r
        ),
        {}
      );

  const getPartnerById = (pid: string | null) => {
    return Object.entries(partners).filter(
      (element) => element[1]?.pid === pid
    );
  };

  const getPartnerByDomain = (partnerDomain: string) => {
    return Object.entries(partners).filter(
      (element) => element[1]?.domain === partnerDomain
    );
  };


  return (
    <PartnerContext.Provider value={{ theme: theme, partnerPid: partnerPid }}>
      {children}
    </PartnerContext.Provider>
  );
};

const usePartner = () => useContext(PartnerContext);
export { usePartner, PartnerContextProvider };
