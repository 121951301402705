import {
    IonPage,
    IonHeader,
    IonToolbar,
    IonGrid,
    IonRow,
    IonButtons,
    IonBackButton,
    IonLabel,
    IonIcon,
    IonContent,
    IonCard,
    IonButton,
    IonFooter,
    IonCol,
    IonItem,
    IonModal,
    IonLoading,
    IonImg,
    IonText,
} from "@ionic/react";
import {
    chevronBack,
    close,
    alertCircleOutline,
    chevronForwardOutline
} from "ionicons/icons";
import styles from "./CommissionDetail.module.scss";
import { useEffect, useRef, useState } from "react";
import { EarningRates, OfferTerms } from "../commission-components";
import { getStoreAffiliateInfo } from "../../../utils/getApiData";
import { NoData } from "../../../components";
import { useParams } from "react-router";
import { useHistory } from "react-router";
import { googleAnalyticsInterstitial } from "../../../utils/reactGa";
import { useUser } from "../../../Contexts/UserContext";

type Param = {
    store: any;
    redirectUrl: any;
};
export const CommissionDetail = () => {
    const modal = useRef<HTMLIonModalElement>(null);
    const history = useHistory();
    const { store } = useParams<Param>();
    const redirectUrl: any = new URLSearchParams(history.location.search).get("url");
    const url = redirectUrl;
    const noCashback: any = new URLSearchParams(history.location.search).get(
        "noCashback"
    );
    const earnUpto: any = new URLSearchParams(history.location.search).get(
        "displayText"
    );
    const productTitle: any = new URLSearchParams(history.location.search).get(
        "title"
    );
    const [isModalOpen, setOpenModal] = useState(false);
    const [isActiveBtn, setActiveBtn] = useState(true);
    const [toolTip, setToolTip] = useState(false);
    const [tooTipDesc, setToolTipDesc] = useState<any>();
    const [earningRates, setEarningRates] = useState<any>(false);
    const [offerTerms, setOfferTerms] = useState<any>(false);
    const [showLoading, setShowLoading] = useState(true);
    const [noData, setNoData] = useState(false);
    const [storeAffiliateData, setStoreAffiliateData] = useState<any>();
    const user = useUser()

    const RequestParams = {
        store: store,
        ...(user?.profileid && { profileid: user?.profileid })
    }

    useEffect(() => {
        const unlisten = history.listen(() => {
            dismiss();
        });
        return () => {
            unlisten();
        };
    }, [history]);

    useEffect(() => {
        (async () => {
            setShowLoading(true);
            try {
                const { data } = await getStoreAffiliateInfo(RequestParams);
                if (data?.info && data?.categories) {
                    setStoreAffiliateData(data);
                } else {
                    setNoData(true);
                    window.open(url, "_blank")
                }
            } catch (err) {
                setNoData(true);
                window.open(url, "_blank")
            }
            setShowLoading(false);
        })();
    }, []);

    function dismiss() {
        modal.current?.dismiss();
        setOpenModal(false);
        setActiveBtn(true);
        setToolTip(false);
        setToolTipDesc("");
    }

    function tooltipHandler(title: string) {
        setToolTip(true);
        setToolTipDesc(title);
    }
    function closeToolTipHandler() {
        setToolTip(false);
        setToolTipDesc("");
    }
    function openModalHandler(type: string) {
        setOpenModal(true);
        if (!earningRates && !offerTerms) {
            setToolTip(false);
        }
        if (type === "earnings-rates") {
            setEarningRates(true);
            setOfferTerms(false);
            setActiveBtn(true);
        } else {
            setEarningRates(false);
            setOfferTerms(true);
            setActiveBtn(false);
        }
    }

    const gaHandler = (button: string) => {
        googleAnalyticsInterstitial(button, store);
    }

    const redirectHandler = () => {
        if (url && url !== 'null') {
            window.open(url, "_blank")
        } else {
            window.open(storeAffiliateData?.info?.storeInfo?.url, "_blank")
        }
    }

    return (
        <IonPage>
            <IonHeader className={`ion-no-padding ion-no-border`}>
                <IonToolbar className="header-primary-bg-color header-text-color">
                    <IonGrid fixed>
                        <IonRow>
                            <IonButtons>
                                <IonBackButton icon={chevronBack} defaultHref="/shopping"></IonBackButton>
                            </IonButtons>
                        </IonRow>
                    </IonGrid>
                </IonToolbar>
                {storeAffiliateData && (
                    <div className={`${styles.logoContainer}`}>
                        <img src={storeAffiliateData?.info?.storeInfo?.image} alt="" />
                    </div>
                )}
            </IonHeader>
            {noData ? (
                <IonGrid className={`${styles.nodata}`}>
                    <IonImg src='/assets/icon/nodata.png'></IonImg>
                    <IonText className='font14 font600'>Unable to fetch more information on {store}.</IonText>
                    <a href={url} target="blank"><IonText className='font14 font600'>Please click here to visit {store}</IonText></a>
                </IonGrid>
            ) : (
                storeAffiliateData && (
                    <IonContent>
                        <div className={`${styles.commissionContentContainer}`}>
                            <IonGrid
                                fixed
                                className="d-flex flex-column ion-align-items-center ion-no-padding"
                            >
                                <div
                                    className={`${styles.contentContainer} d-flex flex-column ion-justify-content-center`}
                                >
                                    <IonRow className="flex-column w100">
                                        <div>
                                            {
                                                productTitle && <div>
                                                    <IonLabel className="font14 font700 ion-no-margin ion-margin ion-text-center d-flex ion-justify-content-center lh-21">
                                                        {earnUpto} on {productTitle}
                                                    </IonLabel>
                                                </div>
                                            }
                                            {storeAffiliateData?.info?.expertTip && (
                                                <IonCard
                                                    className={`${styles.expertTip} ion-no-margin ion-margin ion-padding-horizontal font12 font500 card-title-color`}
                                                >
                                                    <span
                                                        dangerouslySetInnerHTML={{
                                                            __html: storeAffiliateData?.info?.expertTip,
                                                        }}
                                                    ></span>
                                                </IonCard>
                                            )}
                                        </div>
                                        <hr className="ion-margin-horizontal" />
                                    </IonRow>
                                    <IonRow className={`${styles.statusRow} ion-padding`}>
                                        <div className={`${styles.status}`}>
                                            <span
                                                className={`${styles.dot} primary-border-color`}
                                            ></span>
                                            <span className={`${styles.vl}`}></span>
                                            <span
                                                className={`${styles.dot} primary-border-color`}
                                            ></span>
                                            <span className={`${styles.vl}`}></span>
                                            <span
                                                className={`${styles.dot} primary-border-color`}
                                            ></span>
                                        </div>
                                        <div
                                            className={`d-flex ion-justify-content-between w100 mart10 ${styles.processDetails}`}
                                        >
                                            <div
                                                className={`d-flex flex-column ion-align-items-start`}
                                                onClick={() =>
                                                    tooltipHandler(
                                                        storeAffiliateData?.info?.timelineText?.speed
                                                            ?.description
                                                    )
                                                }
                                            >
                                                <IonIcon
                                                    icon={alertCircleOutline}
                                                    className={`font18 font500 icon-dark-color`}
                                                ></IonIcon>
                                                <p className={`font10 font500 ion-text-start`}>
                                                    {storeAffiliateData?.info?.timelineText?.speed?.title}
                                                </p>
                                            </div>
                                            <div
                                                className={`d-flex flex-column ion-align-items-center`}
                                                onClick={() =>
                                                    tooltipHandler(
                                                        storeAffiliateData?.info?.timelineText?.claim
                                                            ?.description
                                                    )
                                                }
                                            >
                                                <IonIcon
                                                    icon={alertCircleOutline}
                                                    className={`font18 font500 icon-dark-color`}
                                                ></IonIcon>
                                                <p className={`font10 font500 ion-text-center`}>
                                                    {storeAffiliateData?.info?.timelineText?.claim?.title}
                                                </p>
                                            </div>
                                            <div
                                                className={`d-flex flex-column ion-align-items-end`}
                                                onClick={() =>
                                                    tooltipHandler(
                                                        storeAffiliateData?.info?.timelineText?.estimate
                                                            ?.description
                                                    )
                                                }
                                            >
                                                <IonIcon
                                                    icon={alertCircleOutline}
                                                    className={`font18 font500 icon-dark-color`}
                                                ></IonIcon>
                                                <p className={`font10 font500 ion-text-end`}>
                                                    {
                                                        storeAffiliateData?.info?.timelineText?.estimate
                                                            ?.title
                                                    }
                                                </p>
                                            </div>
                                        </div>
                                        <div>
                                            {toolTip && (
                                                <IonCard
                                                    className={`${styles.toolTipCard} ion-no-padding ion-no-margin ion-margin-horizontal`}
                                                >
                                                    <IonItem lines="none">
                                                        <p className="font10 font500 ion-text-center marl10 marr10">
                                                            {tooTipDesc}
                                                        </p>
                                                        <IonIcon
                                                            icon={close}
                                                            slot="end"
                                                            color="dark"
                                                            className="font18 ion-align-self-start ion-no-margin"
                                                            onClick={() => setToolTip(false)}
                                                        ></IonIcon>
                                                    </IonItem>
                                                </IonCard>
                                            )}
                                        </div>
                                    </IonRow>
                                </div>
                                {(noCashback == 0 || !noCashback) && (
                                    <IonRow className={`${styles.earnUptoBtn}`}>
                                        <IonButton
                                            className="secondary-btn font14 font600"
                                            onClick={() => redirectHandler()}
                                        >
                                            <IonLabel className="marr10">
                                                {earnUpto
                                                    ? earnUpto
                                                    : storeAffiliateData?.info?.storeInfo?.title}
                                            </IonLabel>
                                            <IonIcon icon={chevronForwardOutline}></IonIcon>
                                        </IonButton>
                                    </IonRow>
                                )}
                                {noCashback == 1 && (
                                    <IonRow className={`${styles.earnUptoClearBtn}`}>
                                        <IonButton
                                            className="primary-border-btn font14 font600 ion-no-padding"
                                            onClick={() => redirectHandler()}
                                        >
                                            <IonLabel className="marr10">
                                                Buy Best Price @ {earnUpto
                                                    ? earnUpto
                                                    : storeAffiliateData?.info?.storeInfo?.title}
                                            </IonLabel>
                                            <IonIcon icon={chevronForwardOutline}></IonIcon>
                                        </IonButton>
                                    </IonRow>
                                )}
                            </IonGrid>
                            <IonModal
                                isOpen={isModalOpen}
                                trigger="open-modal"
                                initialBreakpoint={1}
                                breakpoints={[1]}
                                ref={modal}
                                handleBehavior="cycle"
                                className={`${styles.modal}`}
                            >
                                <IonHeader className="ion-no-border">
                                    <IonItem className={styles.filterHeader} lines="none">
                                        <IonLabel class="font12 font500">
                                            {earningRates ? "Earnings Rates" : "Offer Terms"}
                                        </IonLabel>
                                        <IonIcon
                                            slot="end"
                                            className="dark"
                                            icon={close}
                                            onClick={() => dismiss()}
                                        />
                                    </IonItem>
                                </IonHeader>
                                <IonContent
                                    className={`ion-bg-white ${styles.filterModal}`}
                                    scrollY={true}
                                >
                                    {earningRates && (
                                        <EarningRates data={storeAffiliateData?.categories} />
                                    )}
                                    {offerTerms && (
                                        <OfferTerms data={storeAffiliateData?.info?.tnc} />
                                    )}

                                    <div>
                                        {toolTip && (
                                            <IonCard
                                                className={`${styles.toolTipModalCard} ion-no-padding ion-no-margin ion-margin-horizontal`}
                                            >
                                                <IonItem lines="none">
                                                    <p className="font10 font500 ion-text-center marl10 marr10">
                                                        {tooTipDesc}
                                                    </p>
                                                    <IonIcon
                                                        icon={close}
                                                        slot="end"
                                                        color="dark"
                                                        className="font18 ion-align-self-start ion-no-margin"
                                                        onClick={() => closeToolTipHandler()}
                                                    ></IonIcon>
                                                </IonItem>
                                            </IonCard>
                                        )}
                                    </div>

                                    <IonCard
                                        className={`${styles.detailsCard} ion-no-margin ion-padding`}
                                    >
                                        <IonItem
                                            lines="none"
                                            className="ion-no-margin ion-no-padding font10 font500"
                                            onClick={() =>
                                                tooltipHandler(
                                                    storeAffiliateData?.info?.timelineText?.estimate
                                                        ?.description
                                                )
                                            }
                                        >
                                            <IonLabel className="ion-no-margin d-block font10 font500 card-title-color">
                                                {
                                                    storeAffiliateData?.info?.timelineText?.estimate
                                                        ?.title
                                                }
                                            </IonLabel>
                                            <IonIcon
                                                icon={alertCircleOutline}
                                                className="font16 font500 icon-dark-color"
                                            ></IonIcon>
                                        </IonItem>
                                        <IonItem
                                            lines="none"
                                            className="ion-no-margin ion-no-padding font10 font500"
                                            onClick={() =>
                                                tooltipHandler(
                                                    storeAffiliateData?.info?.timelineText?.speed
                                                        ?.description
                                                )
                                            }
                                        >
                                            <IonLabel className="ion-no-margin d-block font10 font500 card-title-color">
                                                {storeAffiliateData?.info?.timelineText?.speed?.title}
                                            </IonLabel>
                                            <IonIcon
                                                icon={alertCircleOutline}
                                                className="font16 font500 icon-dark-color"
                                            ></IonIcon>
                                        </IonItem>
                                        <IonItem
                                            lines="none"
                                            className="ion-no-margin ion-no-padding font10 font500"
                                            onClick={() =>
                                                tooltipHandler(
                                                    storeAffiliateData?.info?.timelineText?.claim
                                                        ?.description
                                                )
                                            }
                                        >
                                            <IonLabel className="ion-no-margin d-block font10 font500 card-title-color">
                                                {storeAffiliateData?.info?.timelineText?.claim?.title}
                                            </IonLabel>
                                            <IonIcon
                                                icon={alertCircleOutline}
                                                className="font16 font500 icon-dark-color"
                                            ></IonIcon>
                                        </IonItem>
                                    </IonCard>
                                </IonContent>
                            </IonModal>
                        </div>
                    </IonContent>
                )
            )}
            {storeAffiliateData && (
                <IonFooter className={`${styles.footer}`}>
                    <IonGrid fixed className="ion-no-padding">
                        <IonRow>
                            <IonCol>
                                <IonButton
                                    className={`${isActiveBtn ? "primary-btn" : "white-btn"
                                        } ion-no-margin font12 font500 `}
                                    expand="block"
                                    onClick={() => { gaHandler('Earnings Rates'); openModalHandler("earnings-rates") }}
                                >
                                    Earnings Rates
                                </IonButton>
                            </IonCol>
                            <IonCol>
                                <IonButton
                                    className={`${!isActiveBtn ? "primary-btn" : "white-btn"
                                        } ion-no-margin font12 font500 `}
                                    expand="block"
                                    onClick={() => { gaHandler('offer Terms'); openModalHandler("offer-terms") }}
                                >
                                    offer Terms
                                </IonButton>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonFooter>
            )}
            <IonLoading
                cssClass="my-custom-class"
                isOpen={showLoading}
                message={"Please wait..."}
            />
        </IonPage>
    );
};
