import { IonBackButton, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCol, IonContent, IonGrid, IonHeader, IonImg, IonLoading, IonPage, IonRow, IonText, IonTitle, IonToolbar } from "@ionic/react";
import { useEffect, useState } from "react";
import { chevronBack } from "ionicons/icons";
import { useHistory, useParams } from "react-router";
import { Footer, NoData } from "../../../components";
import { getCouponDetail } from '../../../utils/getApiData'
import { esdCouponDetailThumbnail, productThumbnail } from "../../../thumbnailConfig";
import styles from './CouponDetail.module.scss'
import { useUser } from "../../../Contexts/UserContext";
import ReactGA from 'react-ga4'
import { googleAnalyticsViewStore } from "../../../utils/reactGa";
import { usePartner } from "../../../Contexts/PartnerContext";

type Coupons = {
  coupons: {
    storeLogo: string;
    couponTitle: string;
    couponCode: string;
    landing_url: string;
    couponDescription: string;
  }[];
}[];

export interface AndroidInterface {
  onCopyToClipboard(text: string): any;
}
declare const Android: AndroidInterface;

export const CouponDetail = () => {
  const [couponDetail, setCouponDetail] = useState<Coupons>([]);
  const [noData, setNoData] = useState(false);
  const [showLoading, setShowLoading] = useState(true);
  const { partnerPid } = usePartner();
  let paramId: { id: string } = useParams();
  const user = useUser()
  let couponId = +paramId.id;
  const history = useHistory();

  const RequestParams = {
    tabId: 189,
    sendSelectedTab: 1,
    page: 1,
    ...(user?.profileid && { profileid: user?.profileid })
  }

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: history.location.pathname + history.location.search });
  }, [history]);

  useEffect(() => {
    (async () => {
      try {
        const { data: { selectedTabItems } } = await getCouponDetail(RequestParams);
        let data: any[] = [];
        selectedTabItems.forEach((element: any) => {
          data.push(element.coupons);
        });
        setCouponDetail(data);
      }
      catch {
        setNoData(true)
      }
      setShowLoading(false)
    })()
  }, [])

  function handleCopyAndRedirect(code: any, url: any) {
    if (typeof Android != 'undefined') {
      Android.onCopyToClipboard(code);
    }
    else {
      navigator.clipboard.writeText(code)
        .then(() => {
          window.location.href = url;
        })
        .catch(err => {
          console.error('Error copying text to clipboard:', err);
        });
    }
  }

  const gaHandler = (coupon: any) => {
    googleAnalyticsViewStore(coupon.logParams, coupon?.landing_url);
  }

  const handleImageFailure = (e: any) => {
    e.target.src = partnerPid === 'esd' ? esdCouponDetailThumbnail : productThumbnail;
  }

  return (
    <IonPage className={styles.couponDetail}>
      <IonHeader className="ion-no-padding ion-no-border">
        <IonToolbar className="header-primary-bg-color header-text-color">
          <IonGrid fixed>
            <IonRow>
              <IonButtons >
                <IonBackButton
                  icon={chevronBack}
                  defaultHref="/coupons"
                ></IonBackButton>
              </IonButtons>
              <IonTitle className={`${styles.couponDetailTitle} font16`}>COUPON</IonTitle>
            </IonRow>
          </IonGrid>
        </IonToolbar>
      </IonHeader>

      {noData ? <><div className="scrollableDesign">
        <div className="scrollable"></div>
      </div><NoData props={"No Data Found !"} /></> : (<IonContent className={`ion-justify-content-center ${styles.content}`}>
        <div className="scrollableDesign">
          <div className="scrollable"></div>
        </div>
        <IonGrid className={`ion-no-padding ${styles.contentGrid}`} fixed>
          <IonRow>
            <IonCol>
              {couponDetail &&
                couponDetail.map(
                  (storeCoupons: any, index) =>
                    storeCoupons &&
                    storeCoupons.map((storeCoupon: any, index: any) => {
                      if (storeCoupon.id === couponId)
                        return (
                          <IonCard className="ion-justify-content-center ion-margin" key={index}>
                            <div className={styles.productImg}>
                              <img src={storeCoupon?.storeLogo} alt="product" onError={(e) => handleImageFailure(e)} />
                            </div>
                            <IonCardHeader>
                              <IonCardTitle className="ion-text-center font12  card-title-color">
                                {storeCoupon.couponTitle}
                              </IonCardTitle>
                            </IonCardHeader>
                            <IonCardContent className="ion-text-center">
                              <IonText
                                className="coupon-code ion-no-padding card-primary-text-color">
                                {storeCoupon.couponCode}
                              </IonText>
                              <div>
                                <IonButton
                                  onClick={() => { gaHandler(storeCoupon); handleCopyAndRedirect(storeCoupon.couponCode, storeCoupon.landing_url) }}
                                  className="ion-margin-vertical ion-text-uppercase font10 secondary-btn"
                                  shape="round">
                                  Copy Code And Shop
                                </IonButton>
                              </div>
                              <div className="font8 card-grey-text-color">{storeCoupon.couponDescription}</div>
                            </IonCardContent>
                          </IonCard>
                        );
                    })
                )}
            </IonCol>
          </IonRow>
        </IonGrid>
        {couponDetail.length > 0 && <Footer />}
      </IonContent>)
      }
      <IonLoading
        cssClass='my-custom-class'
        isOpen={showLoading}
        message={'Please wait...'}
      />
    </IonPage >
  );
};


