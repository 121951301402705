import { IonBackButton, IonButtons, IonGrid, IonHeader, IonIcon, IonImg, IonLabel, IonRow, IonSegment, IonSegmentButton, IonToolbar } from "@ionic/react"
import { chevronBack } from "ionicons/icons";
import { useHistory } from "react-router-dom"
import styles from './TabHome.module.scss'

export const TabHome = () => {
    const history = useHistory();
    const SHOPPING_DOMAIN = 'easidesimall.travelx.ai';

    const backButton = (() => {
        window.location.href = 'https://easidesi.travelx.ai/home'
    })

    return (
        <div>
            <IonGrid fixed className={`${styles.tabHome} ion-padding-horizontal ion-no-padding`}>
                {window.location.hostname === SHOPPING_DOMAIN &&
                    <IonButtons>
                        <IonIcon className={`${styles.backIcon}`} icon={chevronBack} onClick={() => backButton()}></IonIcon>
                    </IonButtons>
                }
                <IonToolbar>
                    <IonSegment value={history.location.pathname} className="ion-no-padding">
                        <IonSegmentButton value="/shopping" onClick={() => history.replace(`/shopping`)}>
                            <IonLabel className="ion-text-capitalize">Shopping</IonLabel>
                        </IonSegmentButton>
                        <IonSegmentButton value="/deals" onClick={() => history.replace(`/deals`)}>
                            <IonLabel className="ion-text-capitalize">Deals</IonLabel>
                        </IonSegmentButton>
                        <IonSegmentButton value="/coupons" onClick={() => history.replace(`/coupons`)}>
                            <IonLabel className="ion-text-capitalize" >Coupons</IonLabel>
                        </IonSegmentButton>
                    </IonSegment>
                </IonToolbar>
            </IonGrid>
        </div>
    )
}
