import { IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCol, IonGrid, IonImg, IonRow, IonText } from "@ionic/react";
import { Cashback } from "../../../../components";
import { esdShoppingCardThumbnail, productThumbnail } from "../../../../thumbnailConfig";
import styles from "./Product.module.scss";
import { googleAnalyticsViewItem } from "../../../../utils/reactGa";
import { usePartner } from "../../../../Contexts/PartnerContext";

type Props = {
  item: any;
};
export const Product = ({ item }: Props) => {
  const { partnerPid } = usePartner();
  const gaHandler = () => {
    googleAnalyticsViewItem(item?.logParams)
  }
  const handleImageFailure = (e: any) => {
    e.target.src = partnerPid === 'esd' ? esdShoppingCardThumbnail : productThumbnail;
  }
  return (

    <IonGrid className={`${styles.product} ion-no-padding`}>
      <IonRow>
        <IonCol className="ion-text-wrap">
          <IonCard className={styles.productSectionCardContainer} onClick={() => gaHandler()} routerLink={`/shopping/product/${item.id || item.listing_id}`} >
            <div className={styles.productImg}>
              <img src={item?.img} alt="product" onError={(e) => handleImageFailure(e)} />
            </div>

            <div className={`${styles.productSectionContentContainer} card-grey-bg-color pad10`}>
              <IonCardHeader className="ion-no-padding">
                <IonCardTitle className="line-1-clip font14 ion-text-capitalize card-title-color" >{item.brand}</IonCardTitle>
                <IonCardSubtitle className={`${styles.productSubtitle} line-2-clip ion-no-margin font10 card-subTitle-color`} >{item.title}</IonCardSubtitle>
              </IonCardHeader>

              <IonCardContent className="ion-no-padding">
                <div className="price">
                  <IonText
                    className={`${styles.productSectionProductPrice} font12 card-title-color`}>
                    {item.origPrice > item.price && <strong>&#8377;{(item.origPrice).toFixed(0)}</strong>}
                  </IonText>
                  &nbsp;
                  <IonText className="font12 card-title-color">
                    <strong>&#8377;{(item.price).toFixed(0)}</strong>
                  </IonText>
                  &nbsp;&nbsp;
                  <IonText className="font12 card-title-color">
                    {item.origPrice > item.price && `(${Math.round(
                      ((item.origPrice - item.price) / item.origPrice) *
                      100
                    )}% off)`}
                  </IonText>
                </div>
                <IonRow>
                  <Cashback rewardText={item?.rewards_campaign} styleName={'cashback'} />
                </IonRow>
              </IonCardContent>
            </div>
          </IonCard>
        </IonCol>
      </IonRow>
    </IonGrid >

  );
};
