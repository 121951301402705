import { IonItem, IonGrid, IonCol, IonRow, IonTitle, IonButton } from "@ionic/react";
import { useHistory } from "react-router";
import { Product } from "../product-home/Product";
import styles from "./ProductCategory.module.scss";
import { googleAnalyticsViewMore } from "../../../../utils/reactGa";

type Props = {
  category: any;
};

export const ProductCategory = ({ category }: Props) => {
  let history = useHistory();
  const gaHandler = () => {
    googleAnalyticsViewMore(category);
  }

  return (
    <IonGrid className={`${styles.productCategory} ion-no-padding`}>
      <IonRow>
        <IonCol>
          <div className="ion-no-padding font10">
            <div className="ion-padding-right">
              <IonItem lines="none">
                <IonTitle className="ion-no-padding primary-title-color">
                  {category?.display_name}
                </IonTitle>
                <IonButton
                  className="font12 ion-no-padding padl10 clear-btn"
                  onClick={() => { gaHandler(); category?.extra_value1 && history.push(`/shopping/pricelist${(category?.extra_value1).split('/').map((part: any) => part === '/' ? part : encodeURIComponent(part)).join('/')}`) }}>
                  View More
                </IonButton>
              </IonItem>
              <div className="scrollx">
                {category?.products.map((item: any, index: number) => {
                  return <Product item={item} key={index} />;
                })}
              </div>
            </div>
          </div>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};
