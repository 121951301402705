import { IonCard } from '@ionic/react'
import styles from './BannerCarousel.module.scss'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Keyboard, Scrollbar, Zoom } from "swiper";
import { esdBannerThumbnail, bannerThumbnail } from '../../thumbnailConfig'
import { googleAnalyticsBanner } from '../../utils/reactGa';
import 'swiper/swiper.min.css';
import '@ionic/react/css/ionic-swiper.css';
import "swiper/css/pagination";
import { useHistory } from 'react-router';
import { usePartner } from '../../Contexts/PartnerContext';

type Props = {
    props: any;
}

export const BannerCarousel = ({ props }: Props) => {
    const history = useHistory();
    const { partnerPid } = usePartner();
    const pagePath = history.location.pathname.substring(1);
    const gaHandler = (item: any, index: number) => {
        googleAnalyticsBanner(pagePath, item, index)
    }

    const handleImageFailure = (e: any) => {
        e.target.src = partnerPid === 'esd' ? esdBannerThumbnail : bannerThumbnail;
    }

    return (
        <div className={styles.bannerCarousel}>
            <Swiper className={`${styles.swiper}`} modules={[Autoplay, Keyboard, Pagination, Scrollbar, Zoom]} autoplay={{ delay: 2500 }} pagination={{ clickable: true }}>
                {
                    props?.banners?.map((item: any, index: any) => {
                        return (
                            <SwiperSlide key={item.id}>
                                <IonCard onClick={() => gaHandler(item, index)}>
                                    <a href={item.redirectURL} target="blank">
                                        <img src={item?.carousel_img} alt='' onError={(e) => handleImageFailure(e)} />
                                    </a>
                                </IonCard>
                            </SwiperSlide>
                        )
                    })
                }
            </Swiper>
        </div>
    )
}