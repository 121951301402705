import { IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCol, IonContent, IonGrid, IonImg, IonLoading, IonPage, IonRow, IonText, IonTitle } from "@ionic/react";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Footer, NoData, TabHome } from "../../../components";
import { useUser } from "../../../Contexts/UserContext";
import { getAllCoupons } from "../../../utils/getApiData";
import { esdCouponCardThumbnail, productThumbnail } from "../../../thumbnailConfig";
import styles from './CouponsHome.module.scss';
import ReactGA from 'react-ga4'
import { googleAnalyticsViewItem } from "../../../utils/reactGa";
import { usePartner } from "../../../Contexts/PartnerContext";

type CouponData = {
  display_name: string;
  coupons: {
    id: number;
    storeLogo: string;
    couponTitle: string;
    couponCode: string;
  }[];
}[]

export const CouponsHome = () => {
  const [couponsData, setCouponsData] = useState<CouponData>([]);
  const [noData, setNoData] = useState(false);
  const [showLoading, setShowLoading] = useState(true);
  const history = useHistory();
  const user = useUser();
  const { partnerPid } = usePartner();

  const RequestParams = {
    pageType: "home",
    type: "coupons",
    sendSelectedTab: 1,
    page: 1,
    ...(user?.profileid && { profileid: user?.profileid })
  }

  const gaHandler = (coupon: any) => {
    googleAnalyticsViewItem(coupon?.logParams);
  }
  const handleImageFailure = (e: any) => {
    e.target.src = partnerPid === 'esd' ? esdCouponCardThumbnail : productThumbnail;
  }

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: history.location.pathname + history.location.search });
  }, [history]);

  useEffect(() => {
    (async () => {
      try {
        const { data: { selectedTabItems } } = await getAllCoupons(RequestParams);
        setCouponsData(selectedTabItems);
      }
      catch {
        setNoData(true)
      }
      setShowLoading(false)
    })()
  }, [])

  return (
    <IonPage className={styles.couponHome}>
      <div className={styles.mshopLogo}>
      {
        partnerPid === 'mos' ? (
          <img src="/assets/icon/MshopLogo.png" alt="logo" />
        ) : partnerPid === 'ast' ? (
          <img src="/assets/icon/ApnaStoreLogo.png" alt="logo" />
        ) : partnerPid === 'spta' ? (
          <img src="/assets/icon/shopstaccLogo.png" alt="logo" />
        ) : partnerPid === 'pwa' ? (
          <img src="/assets/icon/scandidlogo.png" alt="logo" />
        ) : partnerPid === 'itt' ? (
          <img src="/assets/icon/MaxEasyStore.png" alt="logo" />
        ) : partnerPid === 'plx' ? (
          <img src="/assets/icon/Pluxee.png" alt="logo" />
        ) : null
      }
      </div>
      <TabHome />
      {noData ? <NoData props={"No Data Found !"} /> : (<IonContent className={styles.content}>
        <IonGrid fixed className={styles.contentGrid}>
          {couponsData.map((store, index) => (
            <div key={index}>
              <IonTitle class={`${styles.storeName} primary-title-color`}>{store.display_name}</IonTitle>
              <IonRow>
                <div className="scroll-item ion-padding-bottom scrollx no-scroll">
                  {store.coupons.map((coupon, index) => (
                    <div className="scroll-item" key={index}>
                      <IonCol className="ion-no-padding">
                        <IonCard
                          className='ion-padding'
                          onClick={() => { gaHandler(coupon); history.push(`/coupons/${coupon.id}`) }}>
                          <div className={styles.productImg}>
                            <img src={coupon.storeLogo} alt="product" onError={(e) => handleImageFailure(e)} />
                          </div>
                          <IonCardHeader className="ion-no-padding ion-margin-vertical ">
                            <IonCardTitle
                              className="ion-text-center line-2-clip font12 card-title-color">
                              {coupon.couponTitle}
                            </IonCardTitle>
                          </IonCardHeader>
                          <IonCardContent className="ion-text-center ion-no-padding">
                            <IonText className="coupon-code ion-no-padding card-primary-text-color">
                              {(coupon.couponCode.toUpperCase()) === "NO COUPON REQUIRED" ? 'NO COUPON CODE' : coupon.couponCode}
                            </IonText>
                            <div className=" font8 ion-margin card-grey-text-color">Copy Coupon Code</div>
                          </IonCardContent>
                        </IonCard>
                      </IonCol>
                    </div>
                  ))}
                </div>

              </IonRow>
            </div>
          ))}
        </IonGrid>
      </IonContent>)
      }
      <IonLoading
        cssClass='my-custom-class'
        isOpen={showLoading}
        message={'Please wait...'}
      />
    </IonPage >
  );
};


